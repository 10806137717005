import React from "react"
import { Link } from "gatsby"
import { VisibilityContext } from "react-horizontal-scrolling-menu"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
export function CardBlog({ title, itemId, date, img, type, slug }) {
  const visibility = React.useContext(VisibilityContext)
  const image = img && getImage(img.localFile)

  let dateFormatted = new Date(date).toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  })
  return (
    <Link
      to={`/blog/${slug}`}
      className="relative group rounded-b-3xl flex flex-col rounded-t-2xl h-full lg:mt-2 mx-2 lg:mb-4 lg:hover:shadow-xl transition-all duration-200 hover:scale-[1.03]"
    >
      <div className="overflow-hidden  flex-shrink-0 h-[340px] w-[290px] lg:h-[360px] lg:w-[360px]">
        <GatsbyImage
          className="h-full object-cover rounded-t-3xl"
          loading="lazy"
          alt=""
          image={image}
        />
      </div>
      <BottomBlock type={type} title={title} date={dateFormatted} />
    </Link>
  )
}

function BottomBlock({ type, title, date }) {
  const [hasMounted, setHasMounted] = React.useState(false)
  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  if (type === "mainPage") {
    return (
      <div className="rounded-b-3xl  bg-white transition-all duration-200 h-full w-[290px]  lg:w-[360px] py-2 px-4 pb-4 flex flex-col font-primary-bold">
        <div className="flex flex-row justify-between items-center pt-2 pb-4">
          <div className="flex items-center">
            <p
              className={` transition-all duration-200  text-purplebglight-50  text-xs lg:text-sm rounded-full font-primary-book`}
            >
              {date}
            </p>
          </div>
        </div>
        <h3 className="font-semibold text-base  text-purplebglight-50  transition-all duration-200  lg:text-xl pb-0  font-primary-bold">
          {title}
        </h3>
      </div>
    )
  }
  if (type === "burra") {
    return (
      <div className=" h-full rounded-b-2xl bg-purplebglight-50 group-hover:bg-white transition-all duration-200   py-2 px-4 flex flex-col font-primary-bold">
        <h3
          className={`font-semibold text-base text-white group-hover:text-purplebglight-50   transition-all duration-200  lg:text-xl pb-2  font-primary-bold`}
        >
          {title}
        </h3>
        <div className="flex flex-row justify-between items-center pt-2 lg:pt-6">
          <div className="flex  items-center">
            <p
              className={` transition-all text-white duration-200 border group-hover:border-purplebglight-50  px-4 py-2 group-hover:text-purplebglight-50  text-xs lg:text-sm rounded-full font-primary-book`}
            >
              {date}
            </p>
          </div>
          <div className="flex  items-center">
            <p
              className={`text-xs lg:text-sm transition-all text-white group-hover:text-purplebglight-50 duration-200 py-3 pr-2 rounded-xl  font-primary-book`}
            >
              More Info
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mt-1 hidden lg:block text-white w-4 h-4"
              viewBox="0 0 21.328 10.32"
            >
              <path
                id="Icon_ionic-ios-arrow-round-forward"
                data-name="Icon ionic-ios-arrow-round-forward"
                d="M21.476,11.449a.576.576,0,0,0-.007.989l4.5,3.273H8.831c-.526,0-.956.312-.956.7s.43.7.956.7H25.966l-4.5,3.273a.579.579,0,0,0,.007.989,1.23,1.23,0,0,0,1.356-.005l6.1-4.461h0a.818.818,0,0,0,.2-.22.513.513,0,0,0,.074-.269.618.618,0,0,0-.274-.489l-6.1-4.461A1.208,1.208,0,0,0,21.476,11.449Z"
                transform="translate(-7.875 -11.252)"
                className="fill-purplebglight-50  transition-all duration-200"
              />
            </svg>
          </div>
        </div>
      </div>
    )
  }
  if (type === "euphoria") {
    return (
      <div className=" h-full rounded-b-2xl bg-teal-50 group-hover:bg-white  transition-all duration-200   py-2 px-4 flex flex-col font-primary-bold">
        <h3
          className={`font-semibold text-base text-black  transition-all duration-200  lg:text-xl pb-2  font-primary-bold`}
        >
          {title}
        </h3>
        <div className="flex flex-row justify-between items-center pt-2 lg:pt-6">
          <div className="flex  items-center">
            <p
              className={` transition-all duration-200 border border-black  px-4 py-2 text-black  text-xs lg:text-sm rounded-full font-primary-book`}
            >
              {date}
            </p>
          </div>
          <div className="flex  items-center">
            <p
              className={`text-xs lg:text-sm transition-all text-black duration-200 py-3 pr-2 rounded-xl  font-primary-book`}
            >
              More Info
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="mt-1 hidden lg:block text-black w-4 h-4"
              viewBox="0 0 21.328 10.32"
            >
              <path
                id="Icon_ionic-ios-arrow-round-forward"
                data-name="Icon ionic-ios-arrow-round-forward"
                d="M21.476,11.449a.576.576,0,0,0-.007.989l4.5,3.273H8.831c-.526,0-.956.312-.956.7s.43.7.956.7H25.966l-4.5,3.273a.579.579,0,0,0,.007.989,1.23,1.23,0,0,0,1.356-.005l6.1-4.461h0a.818.818,0,0,0,.2-.22.513.513,0,0,0,.074-.269.618.618,0,0,0-.274-.489l-6.1-4.461A1.208,1.208,0,0,0,21.476,11.449Z"
                transform="translate(-7.875 -11.252)"
                className="fill-black  transition-all duration-200"
              />
            </svg>
          </div>
        </div>
      </div>
    )
  }
}
