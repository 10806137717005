import React from "react"
import { ScrollMenu } from "react-horizontal-scrolling-menu"
import { LeftArrow, RightArrow } from "../components/arrows"
import { CardBlog } from "../components/card-blog"
import usePreventBodyScroll from "../hooks/use-prevent-body-scroll"
import useElementPosition from "../hooks/use-element-position"
import { Link } from "gatsby"
export default function LatestNews({
  blogs,
  color,
  eventsCarouselBg,
  cardFontColorClass,
  type,
  cardBorderColorClass,
}) {
  const [position, ref] = useElementPosition()

  const elemPrefix = "test"
  const getId = index => `${elemPrefix}${index}`
  const { disableScroll, enableScroll } = usePreventBodyScroll()
  // const events = [
  //   {
  //     id: 1,
  //     title: "Walhsted",
  //     date: "15.7.",
  //     img: walhsted,
  //   },
  //   {
  //     id: 2,
  //     title: "Austria Goes Zrce",
  //     date: " 16.07. - 23.07",
  //     img: aus,
  //   },
  //   {
  //     id: 3,
  //     title: "100% GANG",
  //     date: "22.07. - 24.7.",
  //     img: gang,
  //   },
  //   {
  //     id: 4,
  //     title: "Regard",
  //     date: "25.7.",
  //     img: regard,
  //   },
  //   {
  //     id: 5,
  //     title: "Headliner Week 2",
  //     date: "06.08. - 12.08",
  //     img: hw2,
  //   },
  //   {
  //     id: 6,
  //     title: "Sonus Festival",
  //     date: "21.08 - 25.08",
  //     img: sonus,
  //   },
  // ]

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          autoplay: true,
          slidesToShow: 2,
          slidesToScroll: 0,
        },
      },
    ],
  }

  function changeTheme(type) {
    switch (type) {
      case "burra":
        return "purplebglight-50"
      case "euphoria":
        return "teal-50"
      default:
        return "white"
    }
  }

  const [items] = React.useState(blogs)
  const Arrows = () => (
    <div className="px-4 lg:px-0 lg:max-w-7xl mx-auto flex pt-8 pb-8 lg:pb-10 lg:pt-0 justify-between lg:justify-end">
      <Link
        to="/blog"
        className={`border-2 text-${changeTheme(
          type
        )} font-primary-bold border-${changeTheme(
          type
        )} rounded-full px-8 py-2 lg:mr-4 hover:bg-white ${
          type === "mainPage"
            ? "hover:text-purplebglight-50"
            : `hover:text-${changeTheme(type)}`
        }   transition-all`}
      >
        All News
      </Link>

      <div>
        <div style={{ display: "flex" }}>
          <LeftArrow type={type} /> <RightArrow type={type} />
        </div>
      </div>
    </div>
  )
  return (
    <div className={`relative bg-yellowdark-50 pb-10`} id="events" ref={ref}>
      <main className="lg:relative pt-20">
        <div>
          <div
            style={{ backgroundColor: eventsCarouselBg }}
            className="slider-custom "
          >
            <div className="slide-track-custom">
              {new Array(14).fill().map((item, index) => (
                <React.Fragment key={index}>
                  <div className="slide-custom">
                    {index === 0 ? (
                      <h2
                        // style={{ color: eventsCarouselFontColor }}
                        className="text-2xl font-primary-bold whitespace-nowrap text-purplebglight-50"
                      >
                        LATEST NEWS
                      </h2>
                    ) : (
                      <span
                        // style={{ color: eventsCarouselFontColor }}
                        className="text-2xl font-primary-bold whitespace-nowrap text-purplebglight-50"
                      >
                        LATEST NEWS
                      </span>
                    )}
                  </div>
                  {index < 13 && (
                    <div className="slide-custom">
                      <span
                        // style={{ color: eventsCarouselFontColor }}
                        className="text-2xl font-primary-bold whitespace-nowrap text-purplebglight-50"
                      >
                        •
                      </span>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {/* <Slider {...settings}>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
          <div className="bg-white py-4 w-full">
            <h3 className="text-menublue-50 text-xl  font-primary-bold whitespace-nowrap">
              UPCOMING EVENTS
            </h3>
          </div>
        </Slider> */}
        <div>
          <ScrollMenu
            scrollContainerClassName="mb-4 lg:pb-14 pb-2 pt-8 flex overflow-scroll"
            transitionBehavior={"smooth"}
            transitionDuration={1000}
            Footer={Arrows}
          >
            {blogs.map(
              ({
                node: {
                  title,
                  id,
                  featuredImage,
                  publishedAt,
                  dateTimeTo,
                  slug,
                  category,
                },
              }) => {
                return (
                  // <h1 key={slug}>test</h1>
                  <CardBlog
                    key={slug}
                    type={type}
                    color={color}
                    title={title}
                    slug={`${category.slug}/${slug}`}
                    cardFontColorClass={cardFontColorClass}
                    cardBorderColorClass={cardBorderColorClass}
                    img={featuredImage}
                    date={publishedAt}
                    itemId={id} // NOTE: itemId is required for track items
                  />
                )
              }
            )}
          </ScrollMenu>
        </div>
      </main>
    </div>
  )
}
