import { useState, useCallback } from "react"

function useElementPosition() {
  const [position, setPosition] = useState({
    top: undefined,
  })

  const ref = useCallback(node => {
    if (node !== null) {
      setPosition({
        top: node.offsetTop,
      })
    }
  }, [])

  return [position, ref]
}

export default useElementPosition
