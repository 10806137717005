import React from "react"

const defaultHeaderClassNames =
  "text-4xl lg:w-full tracking-tight font-primary-bold text-white sm:text-5xl md:text-6xl" +
  " lg:text-5xl xl:text-6xl"

export function Header({ children, additionalClassName = "" }) {
  return (
    <h1 className={`${defaultHeaderClassNames} ${additionalClassName}`}>
      {children}
    </h1>
  )
}
// EVENT DETAIL
const eventTitleClassname = "font-primary-bold text-white text-xl sm:text-4xl"

export function EventDetailTitle({ children, additionalClassName }) {
  return <h1 className={eventTitleClassname}>{children}</h1>
}

const eventDateClassname = "font-primary-bold text-white text-xs sm:text-3xl"
export function EventDetailDate({ children, additionalClassName }) {
  return (
    <p className={eventDateClassname + " " + additionalClassName}>{children}</p>
  )
}

const eventDetailTextOneClassname =
  "font-bold text-fontpurple-50 text-xl sm:text-2xl"
export function EventDetailTextOne({ children, additionalClassName }) {
  return (
    <p
      className={eventDetailTextOneClassname + " " + additionalClassName}
      style={{
        letterSpacing: "0.1px",
        lineHeight: "36px",
      }}
    >
      {children}
    </p>
  )
}

const eventDetailTextTwoClassname = "text-fontpurple-50 text-xl sm:text-xl"
export function EventDetailTextTwo({ children, additionalClassName }) {
  return (
    <p
      className={eventDetailTextTwoClassname + " " + additionalClassName}
      style={{
        letterSpacing: "0.18px",
        lineHeight: "34px",
      }}
    >
      {children}
    </p>
  )
}

// BLOG DETAIL
const blogDetailTextOneClassname =
  "font-bold text-fontpurple-50 text-2xl sm:text-xl"
export function BlogDetailTextOne({ children, additionalClassName }) {
  return (
    <h1
      className={eventDetailTextOneClassname + " " + additionalClassName}
      style={{
        letterSpacing: "0.1px",
        lineHeight: "36px",
      }}
    >
      {children}
    </h1>
  )
}
