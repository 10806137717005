import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"

export function OutlinedButton({
  onClick,
  children,
  additionalClassName = "",
  disabled,
  white,
}) {
  return (
    <div
      onClick={onClick}
      role="button"
      className={`block p-1 flex flex-row  justify-center items-center font-secondary border-2 border-white
            rounded-full text-white font-base h-12 px-6 text-base no-underline ${additionalClassName}`}
    >
      {children}
    </div>
  )
}

export function Next({
  onClick = () => ({}),
  additionalClassName,
  disabled,
  white,
}) {
  return (
    <div
      onClick={onClick}
      className={`flex justify-center items-center h-10 w-10 rounded-full ${
        white ? "text-white" : "text-fontpurple-50"
      } border-2
      ${
        white ? "border-white" : "border-fontpurple-50"
      } ${additionalClassName} ${disabled && "opacity-50"}`}
    >
      <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4" />
    </div>
  )
}

export function Prev({ onClick, disabled, white }) {
  return (
    <div
      onClick={onClick}
      className={`flex justify-center items-center h-10 w-10 rounded-full ${
        white ? "text-white" : "text-fontpurple-50"
      } border-2
      ${white ? "border-white" : "border-fontpurple-50"} ${
        disabled && "opacity-50"
      }`}
    >
      <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4" />
    </div>
  )
}
